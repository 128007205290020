import React from 'react';
import Container from './../../components/container/container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './page-title.scss';
import { Link } from 'gatsby';

const PageTitle = ({ children }) => {
  return (
    <div className="page-title">
      <Container>
        <h1>{children}</h1>
        <ul className="breadcrumbs">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li className="arrow">
            <FontAwesomeIcon icon={['fa', 'long-arrow-alt-right']} />
          </li>
          <li>{children}</li>
        </ul>
      </Container>
    </div>
  );
};

export default PageTitle;
