import * as he from 'he';
import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../components/layout/layout';
import SEO from '../components/seo';
import renderParagraph from '../views/render-paragraph';
import PageTitle from '../components/page-title/page-title';

class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: typeof window !== 'undefined' ? window.innerWidth : 320
    };
  }

  componentDidMount() {
    if (typeof window !== 'undefined')
      window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined')
      window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({
      width: typeof window !== 'undefined' ? window.innerWidth : 320
    });
  };

  disableParallax(windowWidth) {
    return windowWidth <= 480;
  }

  render() {
    const { data, location, pageContext } = this.props;
    const { langCode, nid } = pageContext;
    return (
      <Layout location={location} lang={langCode} nid={nid}>
        <SEO
          title={he.decode(data.nodeLandingspagina.title)}
          description={
            this.props.data.nodeLandingspagina.field_meta_description
          }
          lang={langCode}
          nid={nid}
          location={location}
        />
        {data.nodeLandingspagina.field_default_heading && (
          <PageTitle>{data.nodeLandingspagina.title}</PageTitle>
        )}
        <div className="paragraph__list">
          {data.nodeLandingspagina.relationships.field_paragraphs.map(
            (paragraph, item) =>
              renderParagraph(
                paragraph,
                item,
                data,
                this.disableParallax(this.state.width),
                pageContext.langCode
              )
          )}
        </div>
      </Layout>
    );
  }
}

export default LandingPage;

export const query = graphql`
  query($slug: String!) {
    allNodeCustomer(sort: { fields: [created], order: DESC }) {
      nodes {
        created
        title
        field_quote
        field_particuliere_klant
        field_location
        field_website {
          uri
        }
        field_logo {
          imageDerivatives {
            links {
              icon_landscape {
                href
              }
            }
          }
        }
      }
    }

    allNodeArticle {
      nodes {
        title
        created
        body {
          summary
          processed
        }
        path {
          alias
        }
        relationships {
          field_media {
            field_media_image {
              imageDerivatives {
                links {
                  blog_overview {
                    href
                  }
                }
              }
            }
          }
        }
      }
    }

    nodeLandingspagina(path: { alias: { eq: $slug } }, status: { eq: true }) {
      title
      status
      path {
        alias
      }
      field_default_heading
      field_meta_description
      relationships {
        field_paragraphs {
          ... on paragraph__klanten_preview {
            id
            field_titel
            field_subtitle {
              value
            }
            field_link {
              title
              uri
            }
            internal {
              type
            }
            relationships {
              field_client {
                created
                title
                field_quote
                field_particuliere_klant
                field_location
                field_website {
                  uri
                }
                field_logo {
                  imageDerivatives {
                    links {
                      icon_landscape {
                        href
                      }
                    }
                  }
                }
              }
            }
          }

          ... on paragraph__navigation {
            id
            internal {
              type
            }
            field_hoofdlink_1 {
              title
              uri
            }
            field_hoofdlink_2 {
              title
              uri
            }
            field_hoofdlink_3 {
              title
              uri
            }
            field_links_2 {
              title
              uri
            }
            field_links_1 {
              title
              uri
            }
            relationships {
              field_image_1 {
                field_media_image {
                  title
                  imageDerivatives {
                    links {
                      story_paragraph {
                        href
                      }
                      story_paragraph_webp {
                        href
                      }
                    }
                  }
                }
              }
              field_image_2 {
                field_media_image {
                  title
                  imageDerivatives {
                    links {
                      story_paragraph {
                        href
                      }
                      story_paragraph_webp {
                        href
                      }
                    }
                  }
                }
              }
              field_image_3 {
                field_media_image {
                  title
                  imageDerivatives {
                    links {
                      story_paragraph {
                        href
                      }
                      story_paragraph_webp {
                        href
                      }
                    }
                  }
                }
              }
              field_color_scheme {
                name
              }
            }
          }
          ... on paragraph__block {
            internal {
              type
            }
            relationships {
              field_type {
                name
              }
            }
          }
          ... on paragraph__page_header {
            internal {
              type
            }
            field_title
            field_breadcrumbs {
              title
              uri
            }
            field_text
            field_cta {
              title
              uri
            }
            relationships {
              field_color_scheme {
                name
              }
              field_alignment {
                name
              }
            }
          }

          ... on paragraph__background_image {
            internal {
              type
            }
            field_title
            field_text
            field_cta {
              title
              uri
            }
            relationships {
              field_alignment {
                name
              }
              field_media {
                field_media_image {
                  alt
                  imageDerivatives {
                    links {
                      story_paragraph {
                        href
                      }
                      story_paragraph_webp {
                        href
                      }
                    }
                  }
                }
              }
            }
          }

          ... on paragraph__hero {
            internal {
              type
            }
            field_title
            relationships {
              field_media {
                field_media_image {
                  alt
                  imageDerivatives {
                    links {
                      hero {
                        href
                      }
                      hero_webp {
                        href
                      }
                    }
                  }
                }
              }
            }
          }

          ... on paragraph__fancy_header {
            internal {
              type
            }
            field_title
            field_text
            field_cta {
              title
              uri
            }
            field_story_image {
              imageDerivatives {
                links {
                  story_large {
                    href
                  }
                  story_large_webp {
                    href
                  }
                }
              }
            }
            relationships {
              field_story_image {
                uri {
                  url
                }
              }
            }
          }

          ... on paragraph__menu_van_de_maand {
            id
            internal {
              type
            }
            field_titel
            relationships {
              field_featured_djar {
                field_title
                field_text
                relationships {
                  field_djar {
                    title
                    relationships {
                      field_media {
                        field_media_image {
                          imageDerivatives {
                            links {
                              djar_card {
                                href
                              }
                              djar_card_webp {
                                href
                              }
                            }
                          }
                        }
                      }
                      field_ingredients {
                        field_photo {
                          imageDerivatives {
                            links {
                              thumb {
                                href
                              }
                              thumb_webp {
                                href
                              }
                            }
                          }
                        }
                        title
                      }
                      field_variants {
                        field_title
                        relationships {
                          field_ingredients {
                            field_photo {
                              imageDerivatives {
                                links {
                                  thumb {
                                    href
                                  }
                                  thumb_webp {
                                    href
                                  }
                                }
                              }
                            }
                            title
                          }
                        }
                      }
                    }
                    field_hashtag
                    field_dyk
                    field_warm_gerecht
                  }
                }
              }
            }
          }

          ... on paragraph__catalogus {
            internal {
              type
            }
            field_title
            relationships {
              field_alignment {
                name
              }
              field_color_scheme {
                name
              }
              field_items {
                field_title
                relationships {
                  field_media {
                    field_media_image {
                      imageDerivatives {
                        links {
                          djar_card {
                            href
                          }
                          djar_card_webp {
                            href
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          ... on paragraph__featured_djar {
            internal {
              type
            }
            field_title
            field_text
            relationships {
              field_djar {
                title
                relationships {
                  field_media {
                    field_media_image {
                      imageDerivatives {
                        links {
                          djar_card {
                            href
                          }
                          djar_card_webp {
                            href
                          }
                        }
                      }
                    }
                  }
                  field_ingredients {
                    field_photo {
                      imageDerivatives {
                        links {
                          thumb {
                            href
                          }
                          thumb_webp {
                            href
                          }
                        }
                      }
                    }
                    title
                  }
                  field_variants {
                    field_title
                    relationships {
                      field_ingredients {
                        field_photo {
                          imageDerivatives {
                            links {
                              thumb {
                                href
                              }
                              thumb_webp {
                                href
                              }
                            }
                          }
                        }
                        title
                      }
                    }
                  }
                }
                field_hashtag
                field_dyk
                field_warm_gerecht
              }
            }
          }

          ... on paragraph__djar_overview {
            internal {
              type
            }
            relationships {
              field_alignment {
                name
              }
              field_djars {
                title
                relationships {
                  field_media {
                    field_media_image {
                      imageDerivatives {
                        links {
                          djar_card {
                            href
                          }
                          djar_card_webp {
                            href
                          }
                        }
                      }
                    }
                  }
                  field_ingredients {
                    field_photo {
                      imageDerivatives {
                        links {
                          thumb {
                            href
                          }
                          thumb_webp {
                            href
                          }
                        }
                      }
                    }
                    title
                  }
                  field_variants {
                    field_title
                    relationships {
                      field_ingredients {
                        field_photo {
                          imageDerivatives {
                            links {
                              thumb {
                                href
                              }
                              thumb_webp {
                                href
                              }
                            }
                          }
                        }
                        title
                      }
                    }
                  }
                }
                field_hashtag
                field_dyk
              }
            }
            field_title
            field_text
          }

          ... on paragraph__focus {
            drupal_internal__id
            internal {
              type
            }
            field_title
            field_text
            field_cta {
              title
              uri
            }
            relationships {
              field_color_scheme {
                name
              }
              field_alignment {
                name
              }
            }
          }

          ... on paragraph__faq {
            internal {
              type
            }
            field_title
            field_text
            relationships {
              field_color_scheme {
                name
              }
              field_alignment {
                name
              }
              field_faq_items {
                ... on paragraph__faq_item {
                  field_title
                  field_text
                }
              }
            }
          }

          ... on paragraph__story {
            internal {
              type
            }
            field_title
            field_text
            field_cta {
              title
              uri
            }
            relationships {
              field_story_style {
                name
              }
              field_media {
                field_media_image {
                  alt
                  imageDerivatives {
                    links {
                      story_paragraph {
                        href
                      }
                      story_paragraph_webp {
                        href
                      }
                    }
                  }
                }
              }
            }
          }

          ... on paragraph__numeration {
            internal {
              type
            }
            field_title
            field_text
            field_number
            relationships {
              field_media {
                field_media_image {
                  alt
                  imageDerivatives {
                    links {
                      hero {
                        href
                      }
                      hero_webp {
                        href
                      }
                    }
                  }
                }
              }
            }
          }

          ... on paragraph__form_story {
            drupal_internal__id
            internal {
              type
            }
            field_title
            field_text
            field_form_id
            relationships {
              field_type {
                name
              }
              field_color_scheme {
                name
              }
            }
          }

          ... on paragraph__form {
            drupal_internal__id
            internal {
              type
            }
            field_title
            field_text
            field_form_id
            relationships {
              field_color_scheme {
                name
              }
            }
          }

          ... on paragraph__ebook_download {
            drupal_internal__id
            internal {
              type
            }
            field_title
            field_text
            field_form_id
            relationships {
              field_color_scheme {
                name
              }

              field_media {
                field_media_image {
                  imageDerivatives {
                    links {
                      story_large {
                        href
                      }
                      story_large_webp {
                        href
                      }
                    }
                  }
                }
              }
            }
          }

          ... on paragraph__newsletter {
            internal {
              type
            }
            field_title
            relationships {
              field_color_scheme {
                name
              }
            }
          }

          ... on paragraph__references {
            internal {
              type
            }
            field_title
            field_text
            field_cta {
              title
              uri
            }
            relationships {
              field_color_scheme {
                name
              }
              field_alignment {
                name
              }
            }
          }

          ... on paragraph__text {
            internal {
              type
            }
            field_text
            relationships {
              field_color_scheme {
                name
              }
              field_alignment {
                name
              }
            }
          }

          ... on paragraph__guidance {
            internal {
              type
            }
            field_box
            field_title
            field_text
            relationships {
              field_color_scheme {
                name
              }
              field_alignment {
                name
              }
              field_guidance_items {
                ... on paragraph__guidance_item {
                  field_title
                  field_text
                  field_button_text
                  field_link {
                    uri
                  }
                  relationships {
                    field_media {
                      field_media_image {
                        imageDerivatives {
                          links {
                            story_paragraph {
                              href
                            }
                            story_paragraph_webp {
                              href
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          ... on paragraph__text_blocks {
            internal {
              type
            }
            field_title
            field_text
            relationships {
              field_color_scheme {
                name
              }
              field_alignment {
                name
              }
              field_text_blocks {
                ... on paragraph__text_block {
                  field_title
                  field_text
                }
              }
            }
          }

          ... on paragraph__usp {
            internal {
              type
            }
            field_title
            field_text
            relationships {
              field_color_scheme {
                name
              }
              field_alignment {
                name
              }
              field_usp_items {
                ... on paragraph__usp_item {
                  field_icon {
                    alt
                    imageDerivatives {
                      links {
                        icon {
                          href
                        }
                      }
                    }
                  }
                  field_title
                  field_text
                  drupal_id
                }
              }
            }
          }
        }
      }
    }
  }
`;
